import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-personal-create',
  templateUrl: './personal-create.component.html',
  styleUrls: ['./personal-create.component.scss']
})
export class PersonalCreateComponent implements OnInit {

  personalGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private authService: AuthService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.personalGroup = this.fb.group({
      nombres: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      numero_carnet: new FormControl('', [Validators.required]),
      telefono_celular: new FormControl('', [Validators.required]),
      tipo_personal: new FormControl('tecnico', [Validators.required]),
    });
  }

  store() {
    let data = null;
    const cuentaGenerado = this.personalGroup.value.nombres.toLowerCase().split(' ')[0] + '.' + this.personalGroup.value.apellidos.toLowerCase().split(' ')[0];
    if (this.personalGroup.value.tipo_personal === 'tecnico' || this.personalGroup.value.tipo_personal === 'supervisor') {
      data = {
        usuario: {
          cuenta: cuentaGenerado,
          password: this.personalGroup.value.numero_carnet,
          c_password: this.personalGroup.value.numero_carnet,
          tipo_usuario: this.personalGroup.value.tipo_personal
        },
        tecnico_supervisor: this.personalGroup.value
      };
    } else {
      data = {
        usuario: {
          cuenta: cuentaGenerado,
          password: this.personalGroup.value.numero_carnet,
          c_password: this.personalGroup.value.numero_carnet,
          tipo_usuario: this.personalGroup.value.tipo_personal
        },
        administrador: this.personalGroup.value
      };
    }
    this.authService.signup(data)
      .subscribe((res: any) => {
        this.toastr.success('Personal Registrado', 'Exito');
        this.router.navigate(['/admin/personal']);
      });
  }

}
