import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class Interceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.search('/login') === -1 &&
      req.url.search('/upload-correspondencias-sponsor') === -1 &&
      req.url.search('/upload-correspondencias-escaneadas') === -1 &&
      req.url.search('/upload-slips') === -1 &&
      req.url.search('multimedia-download') === -1 &&
      req.url.search('https://fcm.googleapis.com/fcm/send') === -1 &&
      req.url.search('https://services.achocallaromero.com:49891/tinkuly/sendsms/kunan') === -1 &&
      req.url.search('https://www.googleapis.com/youtube/v3/search') === -1) {
      if (localStorage.getItem('token-eds') !== null) {
        const tokenEds = atob(localStorage.getItem('token-eds'));
        const customReq = req.clone({
          headers: new HttpHeaders()
            .set('Authorization', tokenEds)
        });
        return next.handle(customReq);
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

}
