import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comunicado',
  templateUrl: './comunicado.component.html',
  styleUrls: ['./comunicado.component.scss']
})
export class ComunicadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
