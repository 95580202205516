import {Component, Input, OnInit} from '@angular/core';
import {Beneficiario} from '../../models/beneficiario';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-beneficiario-modal',
  templateUrl: './beneficiario-modal.component.html',
  styleUrls: ['./beneficiario-modal.component.scss']
})
export class BeneficiarioModalComponent implements OnInit {

  @Input() beneficiario: Beneficiario;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close('Click para cerrar');
  }

}
