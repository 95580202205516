import {Component, Input, OnInit} from '@angular/core';
import {HorarioService} from '../horario.service';
import {faEdit, faFileExcel, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-horario-index',
  templateUrl: './horario-index.component.html',
  styleUrls: ['./horario-index.component.scss']
})
export class HorarioIndexComponent implements OnInit {

  @Input() horarioSettings: any;
  horarios: any = null;

  faEdit = faEdit;
  faTrash = faTrash;
  faFileExcel = faFileExcel;
  constructor(private horarioService: HorarioService,
              private toastr: ToastrService) { }
  ngOnInit() {
    this.horarioService.get(this.horarioSettings.evento_id)
      .subscribe((res: any) => {
        this.horarios = res;
      });
  }

  exportExcel(horarioId: number) {
    this.horarioService.beneficiarios(horarioId)
      .subscribe((res: any) => {
        this.horarioService.exportAsExcelFile(res, res.evento_horario[0].evento);
      });
  }

  reload() {
    this.horarioService.get(this.horarioSettings.evento_id)
      .subscribe((res: any) => {
        this.horarios = res;
      });
  }
  destroy(horarioId: number) {
    if (confirm('¿Esta seguro de eliminar el horario?')) {
      this.horarioService.destroy(horarioId)
        .subscribe((res: any) => {
          this.reload();
          this.toastr.success('Horario eliminado', 'Éxito');
        });
    }
  }
}
