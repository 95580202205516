import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-modelos-programaticos',
  templateUrl: './modelos-programaticos.component.html',
  styleUrls: ['./modelos-programaticos.component.scss']
})
export class ModelosProgramaticosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
