import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {PoliticasPrivacidadComponent} from './politicas-privacidad/politicas-privacidad.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
/*  {
    path: 'usuario',
    loadChildren: () => import('./beneficiario/beneficiario.module').then(mod => mod.BeneficiarioModule)
  },*/
  {
    path: 'usuario',
    loadChildren: () => import('./publico/publico.module').then(mod => mod.PublicoModule)
  },
  {
    path: 'invitado',
    loadChildren: () => import('./publico/publico.module').then(mod => mod.PublicoModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'politicas-privacidad',
    component: PoliticasPrivacidadComponent
  },
  {
    path: '',
    redirectTo: 'invitado',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
