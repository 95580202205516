import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {
  faBackward,
  faCheck,
  faComments,
  faEdit,
  faEnvelopeOpenText,
  faFastBackward,
  faFastForward,
  faForward,
  faMailBulk,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
  faUpload,
  faUserFriends,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {PersonalService} from '../../personal/personal.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ComunicadoService} from '../../../services/comunicado.service';
import {RequestComunicadoPeriodo} from '../../../models/request-comunicado-periodo';

@Component({
  selector: 'app-comunicado-index',
  templateUrl: './comunicado-index.component.html',
  styleUrls: ['./comunicado-index.component.scss']
})
export class ComunicadoIndexComponent implements OnInit {

  faPlus = faPlus;
  faUserPlus = faUserPlus;
  faUserFriends = faUserFriends;
  faUpload = faUpload;
  faEdit = faEdit;
  faTrash = faTrash;
  faSearch = faSearch;
  faCheck = faCheck;
  faTimes = faTimes;
  faMailBulk = faMailBulk;
  faComments = faComments;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faEnvelope = faEnvelope;

  /*icons of pagination*/
  faBackward = faBackward;
  faFastBackward = faFastBackward;
  faForward = faForward;
  faFastForward = faFastForward;

  items = 10;

  valor = '';
  gestiones: any[] = [];
  meses: any[] = [];
  gestion = new Date().getFullYear();
  mes = new Date().getMonth() + 1;

  estadistico: any = null;
  spinnerSettings = environment.spinnerSettings;
  color = '#16874F';
  comunicados: any = null;

  constructor(private personalService: PersonalService,
              private comunicadoService: ComunicadoService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
      this.gestiones.unshift({
        gestion: i
      });
    }
    meses.map((mes: string, index: number) => {
      this.meses.push({
        mes,
        digito: index + 1
      });
    });

    this.comunicadoService.comunicadosEstadistico(new RequestComunicadoPeriodo())
      .subscribe((res: any) => {
        this.estadistico = res;
      });

    this.comunicadoService.comunicadosPeriodo(new RequestComunicadoPeriodo())
      .subscribe((res: any) => {
        this.comunicados = res;
        this.spinner.hide('spinner');
      });
  }

  filtrar() {
    const req = new RequestComunicadoPeriodo();
    req.valor = this.valor;
    req.items = this.items;
    req.gestion = this.gestion;
    req.mes = this.mes;

    this.spinner.show('spinner', this.spinnerSettings);
    this.comunicadoService.comunicadosEstadistico(req)
      .subscribe((res: any) => {
        this.estadistico = res;
      });
    this.comunicadoService.comunicadosPeriodo(req)
      .subscribe((res: any) => {
        this.comunicados = res;
        this.spinner.hide('spinner');
      });

  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    const req = new RequestComunicadoPeriodo();
    req.gestion = this.gestion;
    req.mes = this.mes;
    req.items = this.items;
    req.valor = this.valor;

    this.comunicadoService.paginateURL(url, req)
      .subscribe((res: any) => {
        this.comunicados = res;
        this.spinner.hide('spinner');
      });
  }

}
