import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {CapacitacionService} from '../../../services/capacitacion.service';

@Component({
  selector: 'app-capacitacion-create',
  templateUrl: './capacitacion-create.component.html',
  styleUrls: ['./capacitacion-create.component.scss']
})
export class CapacitacionCreateComponent implements OnInit {

  capacitacionGroup: FormGroup;
  show: boolean = false;
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private capacitacionService: CapacitacionService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.capacitacionGroup = this.fb.group({
      titulo: new FormControl('', [Validators.required]),
      facilitador: new FormControl('', [Validators.required]),
      fecha_inicio_inscripcion: new FormControl('', [Validators.required]),
      fecha_fin_inscripcion: new FormControl('', [Validators.required]),
      fecha_inicio_capacitacion: new FormControl('', [Validators.required]),
      fecha_fin_capacitacion: new FormControl('', [Validators.required]),
      modalidad: new FormControl('', [Validators.required]),
      tipo_capacitacion: new FormControl('', [Validators.required]),
      descripcion: new FormControl(''),
      aviso: new FormControl(''),
      lugar: new FormControl(''),
      latitud: new FormControl(''),
      longitud: new FormControl(''),
      enlace: new FormControl(''),
      visible: new FormControl(false),
    });
  }

  store() {
    this.capacitacionService.store(this.capacitacionGroup.value)
      .subscribe(() => {
        this.toastr.success('capacitacion registrada', 'Exito');
        this.router.navigate(['/admin/capacitaciones']);
      });
  }

}
