import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  base = environment.base;
  constructor(private http: HttpClient) { }

  store(data: any) {
    return this.http.post(`${this.base}multimedia`, data);
  }

  download(multimediaId: number) {
    return this.http.get(`${this.base}multimedia/${multimediaId}`);
  }
}
