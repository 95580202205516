import {Component, OnInit} from '@angular/core';
import {EventoService} from '../../admin/evento/evento.service';
import {faCalendarAlt, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
  selector: 'app-evento-publico',
  templateUrl: './evento-publico.component.html',
  styleUrls: ['./evento-publico.component.scss']
})
export class EventoPublicoComponent implements OnInit {

  eventos = null;
  faCalendarAlt = faCalendarAlt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(private eventoService: EventoService) {
    this.eventoService.ultimosEventos()
      .subscribe((res: any) => {
        const eventos: any[] = res;
        this.eventos = eventos.map(item => {
          return {
            ...item,
            show: false
          };
        });

      });
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
}
