import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../auth.service';
import {EventoService} from '../evento.service';

@Component({
  selector: 'app-evento-create',
  templateUrl: './evento-create.component.html',
  styleUrls: ['./evento-create.component.scss']
})
export class EventoCreateComponent implements OnInit {

  eventoGroup: FormGroup;
  value = '';
  personal = null;
  editorConfig = null;
  spinnerSettings = environment.spinnerSettings;
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authService: AuthService,
              private eventoService: EventoService) {
    this.setConfigEditor();
    this.createForm();
  }

  setConfigEditor() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '450',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Ingrese el contenido aquí...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top'
    };
  }

  ngOnInit() {
    this.authService.currentAuth.subscribe((auth) => {
      this.personal = auth;
    });
  }

  createForm() {
    this.eventoGroup = this.fb.group({
      titulo: new FormControl('', [Validators.required]),
      contenido: new FormControl('', [Validators.required]),
      facilitadores: new FormControl('', [Validators.required]),
      fecha_limite_enrolamiento: new FormControl('', [Validators.required]),
      fecha_inicio: new FormControl('', [Validators.required]),
      fecha_fin: new FormControl('', [Validators.required]),
      personal_id: new FormControl(0, [Validators.required]),
      menores_5: new FormControl(false),
      entre_6_14: new FormControl(false),
      entre_15_24: new FormControl(false),
      me_quiero_me_cuido: new FormControl(false),
      patrocinio: new FormControl(false),
      publico: new FormControl(false),
      horario: new FormGroup({
        inicio: new FormControl('', [Validators.required]),
        fin: new FormControl('', [Validators.required]),
        lugar: new FormControl('', [Validators.required]),
        direccion: new FormControl('', [Validators.required]),
        cupo_maximo: new FormControl('', [Validators.required])
      })
    });
  }

  validation() {
    let counter = 0;
    if (this.eventoGroup.value.menores_5 === 1 || this.eventoGroup.value.menores_5 === true) {
      counter++;
    }
    if (this.eventoGroup.value.entre_6_14 === 1 || this.eventoGroup.value.entre_6_14 === true) {
      counter++;
    }
    if (this.eventoGroup.value.entre_15_24 === 1 || this.eventoGroup.value.entre_15_24 === true) {
      counter++;
    }
    if (this.eventoGroup.value.me_quiero_me_cuido === 1 || this.eventoGroup.value.me_quiero_me_cuido === true) {
      counter++;
    }
    if (this.eventoGroup.value.patrocinio === 1 || this.eventoGroup.value.patrocinio === true) {
      counter++;
    }
    if (this.eventoGroup.value.publico === 1 || this.eventoGroup.value.publico === true) {
      counter++;
    }
    return counter !== 0;
  }
  getTopics() {
    const topics = [];
    if (this.eventoGroup.value.menores_5) {
      topics.push('/topics/menores_5');
    }
    if (this.eventoGroup.value.entre_6_14) {
      topics.push('/topics/entre_6_14');
    }
    if (this.eventoGroup.value.entre_15_24) {
      topics.push('/topics/entre_15_24');
    }
    if (this.eventoGroup.value.me_quiero_me_cuido) {
      topics.push('/topics/me_quiero_me_cuido');
    }
    if (this.eventoGroup.value.patrocinio) {
      topics.push('/topics/patrocinio');
    }
    if (this.eventoGroup.value.publico) {
      topics.push('/topics/publico');
    }
    return topics;
  }
  sendNotifications(eventoId: number) {
    const topics = this.getTopics();
    topics.forEach((topic: string) => {
      const data = {
        to: topic,
        data: {
          titulo: this.eventoGroup.value.titulo,
          contenido: this.eventoGroup.value.contenido,
          tipo_publicacion: 'evento',
          publicacion_id: eventoId
        }
      };
      this.eventoService.sendNotification(data)
        .subscribe((confirmacion: any) => {
          console.log(confirmacion);
        });
    });
  }
  store() {
    this.eventoGroup.patchValue({
      personal_id: this.personal.personal_id
    });
    if (this.validation() === true) {
      this.getTopics();
      this.spinner.show('spinner', this.spinnerSettings);
      this.eventoService.store(this.eventoGroup.value)
        .subscribe((res: any) => {
          this.sendNotifications(res.evento_id);
          this.spinner.hide('spinner');
          this.toastr.success('Evento registrado', 'Éxito');
          this.router.navigate(['/admin/eventos']);
        }, (error: any) => {
          this.spinner.hide('spinner');
          this.toastr.error('Se recomienda quitar algunas imágenes y agregarlas por separado', 'Contenido muy largo');
        });

    } else {
      this.toastr.info('Por favor registre al menos un programa', 'Revisar');
    }
  }
}
