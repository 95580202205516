import { Component, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-estructura-comite',
  templateUrl: './estructura-comite.component.html',
  styleUrls: ['./estructura-comite.component.scss']
})
export class EstructuraComiteComponent implements OnInit {
  faWhatsapp = faWhatsapp;

  constructor() { }

  ngOnInit() {
  }

}
