import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {PersonalService} from '../personal.service';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'app-personal-edit',
  templateUrl: './personal-edit.component.html',
  styleUrls: ['./personal-edit.component.scss']
})
export class PersonalEditComponent implements OnInit {

  personalId: number = null;
  personal: any = null;
  personalGroup: FormGroup;
  spinnerSettings = environment.spinnerSettings;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private personalService: PersonalService) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.route.params.subscribe((param: any) => {
      this.personalId = param.id;
      this.personalService.show(param.id)
        .subscribe((personal: any) => {
          this.personal = personal;
          this.createForm();
          this.spinner.hide('spinner');
        });
    });
  }

  ngOnInit() {
  }

  createForm() {
    this.personalGroup = this.fb.group({
      nombres: new FormControl(this.personal.nombres.toUpperCase(), [Validators.required]),
      apellidos: new FormControl(this.personal.apellidos.toUpperCase(), [Validators.required]),
      numero_carnet: new FormControl(this.personal.numero_carnet.toUpperCase(), [Validators.required]),
      telefono_celular: new FormControl(this.personal.telefono_celular.toUpperCase(), [Validators.required]),
      tipo_personal: new FormControl(this.personal.tipo_personal, [Validators.required]),
    });
  }

  update() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.personalService.update(this.personalGroup.value, this.personalId)
      .subscribe((res: any) => {
        this.spinner.hide('spinner');
        this.toastr.success('Personal actualizado', 'Éxito');
        this.router.navigate(['/admin/personal']);
      }, (error: any) => {
        this.spinner.hide('spinner');
        this.toastr.error('Ocurrio un error al actualizar la información', 'intente nuevamente');
      });
  }
}
