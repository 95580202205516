import {Component, OnInit} from '@angular/core';
import {
  faBackward,
  faEdit, faEye, faFastBackward, faFastForward, faForward,
  faPlus,
  faSearch,
  faTrash,
  faUpload,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';
import {NoticiaService} from '../noticia.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'app-noticia-index',
  templateUrl: './noticia-index.component.html',
  styleUrls: ['./noticia-index.component.scss']
})
export class NoticiaIndexComponent implements OnInit {

  faPlus = faPlus;
  faUserFriends = faUserFriends;
  faUpload = faUpload;
  faEdit = faEdit;
  faTrash = faTrash;
  faSearch = faSearch;
  faEye = faEye;

  /*icons of pagination*/
  faBackward = faBackward;
  faFastBackward = faFastBackward;
  faForward = faForward;
  faFastForward = faFastForward;
  color = '#16874F';

  noticias: any = null;
  programa = 'publico';
  items = 10;
  valor = '';
  spinnerSettings = environment.spinnerSettings;

  constructor(private noticiaService: NoticiaService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    environment.spinnerSettings.color = '#16874F';
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);

    const data = {
      items: this.items,
      programa: this.programa
    };
    this.noticiaService.programa(data)
      .subscribe((res: any) => {
        this.noticias = res;
        this.spinner.hide('spinner');
      });
  }

  paginate() {
    const data = {
      items: this.items,
      programa: this.programa
    };
    this.spinner.show('spinner', this.spinnerSettings);
    this.noticiaService.programa(data)
      .subscribe((res: any) => {
        this.noticias = res;
        this.spinner.hide('spinner');
      });
  }

  paginateURL(url: string) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.noticiaService.paginateURL(url)
      .subscribe((res: any) => {
        this.noticias = res;
        this.spinner.hide('spinner');
      });
  }

  setColor(programa: string) {
    switch (programa) {
      case 'publico':
        this.color = '#16874F';
        environment.spinnerSettings.color = '#16874F';
        break;
      case 'patrocinio':
        this.color = '#C60917';
        environment.spinnerSettings.color = '#C60917';
        break;
      case 'menores_5':
        this.color = '#FF9000';
        environment.spinnerSettings.color = '#FF9000';
        break;
      case 'me_quiero_me_cuido':
        this.color = '#87BF36';
        environment.spinnerSettings.color = '#87BF36';
        break;
      case 'entre_6_14':
        this.color = '#6E2673';
        environment.spinnerSettings.color = '#6E2673';
        break;
      case 'entre_15_24':
        this.color = '#207BBC';
        environment.spinnerSettings.color = '#207BBC';
        break;
    }
  }

  setPrograma(programa: string) {
    this.programa = programa;
    this.setColor(programa);
    this.spinner.show('spinner', this.spinnerSettings);
    const data = {
      items: this.items,
      programa: this.programa
    };
    this.noticiaService.programa(data)
      .subscribe((res: any) => {
        this.noticias = res;
        this.spinner.hide('spinner');
      });
  }

  search() {
    if (this.valor.trim().length === 0) {
      this.paginate();
    } else {
      const data = {
        items: this.items,
        programa: this.programa,
        valor: this.valor
      };
      this.spinner.show('spinner', this.spinnerSettings);
      this.noticiaService.search(data)
        .subscribe((res: any) => {
          this.noticias = res;
          this.spinner.hide('spinner');
        });
    }
  }

  destroy(noticiaId: number) {
    if (confirm('¿Esta seguro de eliminar la noticia?')) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.noticiaService.destroy(noticiaId)
        .subscribe((res: any) => {
          this.paginate();
          this.toastr.success('Noticia quitada exitosamente', 'Éxito');
          this.spinner.hide('spinner');
        });
    }
  }
}
