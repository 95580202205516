import {TipoCorrespondencia} from './tipo-correspondencia';
import {Personal} from './personal';
import {Beneficiario} from './beneficiario';

export class Correspondencia {

  constructor(
    public tipo_correspondencia_id: number,
    public lote_id: number,
    public child_number: string,
    public semaforo_tecnico: string,
    public semaforo_supervisor: string,
    public fecha_limite_tecnico: string,
    public fecha_limite_supervisor: string,
    public village: string,
    public mcs_letter_type: string,
    public mail_control_slip: string,
    public mcs_date: string,
    public mcs_date_final: string,
    public donor_name: string,
    public alliance_name: string,
    public tipo: string,
    public observacion: string,
    public estado_tecnico: 'sin_revisar' | 'enviado' | 'observado',
    public estado_supervisor: 'delegado' | 'sin_revisar' | 'validado' | 'devuelto',
    public correspondencia_id?: number,
    public comments?: string,
    public correspondencia_traducida?: string,
    public correspondencia_original?: string,
    public correspondencia_escaneada?: string,
    public tecnico_id?: number,
    public supervisor_id?: number,
    public supervisor?: Personal,
    public tecnico?: Personal,
    public beneficiario?: Beneficiario,
    public tipo_correspondencia?: TipoCorrespondencia
  ) {
  }
}
