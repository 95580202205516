import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-nsp',
  templateUrl: './nsp.component.html',
  styleUrls: ['./nsp.component.scss']
})
export class NspComponent implements OnInit {

  images = [
    {
      src: '/assets/img/landing_page/nsp/NSP-1.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '/assets/img/landing_page/nsp/NSP-2.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '/assets/img/landing_page/nsp/NSP-3.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '/assets/img/landing_page/nsp/NSP-4.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '/assets/img/landing_page/nsp/NSP-5.jpg',
      title: 'titulo',
      description: 'descripcion'
    },
    {
      src: '/assets/img/landing_page/nsp/NSP-6.jpg',
      title: 'titulo',
      description: 'descripcion'
    },

  ];

  constructor() {
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
