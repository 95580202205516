import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import {ZonaService} from '../../../services/zona.service';

@Component({
  selector: 'app-zona-create',
  templateUrl: './zona-create.component.html',
  styleUrls: ['./zona-create.component.scss']
})
export class ZonaCreateComponent implements OnInit {
  zonaGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private zonaService: ZonaService,
              private router: Router,
              private authService: AuthService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.zonaGroup = this.fb.group({
      nombre: new FormControl('', [Validators.required]),
      codigo: new FormControl('', [Validators.required]),
      direccion: new FormControl(''),
      latitud: new FormControl(''),
      longitud: new FormControl(''),
    });
  }

  store() {
    this.zonaService.store(this.zonaGroup.value)
      .subscribe((res) => {
        this.toastr.success('Zona registrado', 'Exito');
        this.router.navigate(['/admin/zonas']);
      });
  }
}
