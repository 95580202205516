import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  faBackward,
  faEdit,
  faFastBackward,
  faFastForward,
  faFileExcel,
  faForward,
  faPlus,
  faSearch,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

import {CapacitacionService} from '../../../services/capacitacion.service';
import {Capacitacion} from '../../../models/capacitacion';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IntegranteService} from '../../../services/integrante.service';
import {ExcelService} from '../../../services/excel.service';

@Component({
  selector: 'app-capacitacion-index',
  templateUrl: './capacitacion-index.component.html',
  styleUrls: ['./capacitacion-index.component.scss']
})
export class CapacitacionIndexComponent implements OnInit {

  faSearch = faSearch;
  faEdit = faEdit;
  faTrash = faTrash;
  faPlus = faPlus;
  faFastBackward = faFastBackward;
  faBackward = faBackward;
  faForward = faForward;
  faFastForward = faFastForward;
  faFileExcel = faFileExcel;
  items = 10;
  capacitaciones: any = null;
  spinnerSettings = environment.spinnerSettings;
  color = '#16874F';

  capacitacionGroup: FormGroup;

  constructor(private capacitacionService: CapacitacionService,
              private fb: FormBuilder,
              private excelService: ExcelService,
              private integranteService: IntegranteService,
              private spinner: NgxSpinnerService) {
    this.spinnerSettings.color = '#16874FEE';
  }

  ngOnInit() {
    this.capacitacionGroup = this.fb.group({
      modalidad: new FormControl('', [Validators.required]),
      tipo_capacitacion: new FormControl('', [Validators.required]),
      valor: new FormControl(''),
    });
    this.spinner.show('spinner', this.spinnerSettings);
    this.capacitacionService
      .paginate('virtual', 'docente', '', 10, 1)
      .subscribe((capacitaciones: any) => {
        this.capacitaciones = capacitaciones;
        this.spinner.hide('spinner');
      });
  }

  /*  paginate() {
      this.spinner.show('spinner', this.spinnerSettings);

      this.capacitacionService.paginate(this.items)
        .subscribe((res: any) => {
          this.personal = res;
          this.spinner.hide('spinner');
        });
    }*/

  paginateURL(url: string) {
    console.log(url);
    /*    this.spinner.show('spinner', this.spinnerSettings);
        this.capacitacionService.paginateURL(url)
          .subscribe((res: any) => {
            this.personal = res;
            this.spinner.hide('spinner');
          });*/
  }

  search() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.capacitacionService.paginate(
      this.capacitacionGroup.get('modalidad').value,
      this.capacitacionGroup.get('tipo_capacitacion').value,
      this.capacitacionGroup.get('valor').value,
      this.items,
      1
    )
      .subscribe((capacitaciones: Capacitacion[]) => {
        this.capacitaciones = capacitaciones;
        this.spinner.hide('spinner');
      });
  }

  descargarListado(capacitacion: Capacitacion) {
    this.integranteService.integrantesCapacitacion(capacitacion.capacitacion_id)
      .subscribe((response: any) => {
        const integrantes = response.integrantes.sort((a, b) => {
          if (a.celular > b.celular) {
            return 1;
          } else {
            return -1;
          }
        });
        this.excelService.exportAsExcelFile(integrantes, `${capacitacion.titulo}`);
      });
  }

  /*  destroy(personal) {
      const mess = 'Esta seguro de eliminar al personal ' + personal.nombres;
      if (confirm(mess)) {
        this.spinner.show('spinner', this.spinnerSettings);
        this.capacitacionService.destroy(personal.personal_id)
          .subscribe((res: any) => {
            this.personal = res;
            this.paginate();
            this.spinner.hide('spinner');
          });
      }
    }*/
}
