import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  faCheck, faCircle, faEdit,
  faHamburger,
  faPlus, faSquare,
  faTimes,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {TestService} from '../test.service';
import {ToastrService} from 'ngx-toastr';
import * as $ from 'jquery';
@Component({
  selector: 'app-test-create',
  templateUrl: './test-create.component.html',
  styleUrls: ['./test-create.component.scss']
})
export class TestCreateComponent implements OnInit {

  @Output() switch = new EventEmitter<any>();
  @Input() settings: any;
  faPlus = faPlus;
  faCheck = faCheck;
  faTimes = faTimes;
  faSquare = faSquare;
  faCircle = faCircle;
  faEdit = faEdit;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faTrash = faTrash;
  showTest = true;
  counter = 0;
  abierta = false;
  test = {
    documentacion_en_linea_id: null,
    titulo: '',
    descripcion: '',
    con_puntuacion: false,
    preguntas: []
  };
  pregunta = {
    pregunta: '',
    tipo_pregunta: 'opcion_unica',
    puntuacion: 0.00,
    opciones: []
  };
  opcion = {
    descripcion: '',
    correcto: false
  };
  constructor(private testService: TestService,
              private toastr: ToastrService) {}

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
    console.log('test: ' + this.settings.documentacion_en_linea_id);
  }

  reloadPage() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
  resetPregunta() {
    this.counter = 0;
    this.pregunta = {
      pregunta: '',
      tipo_pregunta: 'opcion_unica',
      puntuacion: 0.00,
      opciones: []
    };
  }
  resetOpcion() {
    this.opcion = {
      descripcion: '',
      correcto: false
    };
  }
  // permite validar las opciones dicotomicas con las otras
  resetOpciones() {
    this.abierta = false;
    if (this.pregunta.tipo_pregunta === 'dicotomica' && this.pregunta.opciones.length > 2) {
      this.pregunta.opciones = this.pregunta.opciones.slice(0, 2);
      this.counter = 2;
    }
    if (this.pregunta.tipo_pregunta === 'abierta') {
      this.abierta = true;
    }
    if (this.pregunta.tipo_pregunta === 'opcion_unica' && this.getCantidadOpciones(this.pregunta) > 1) {
      this.pregunta.opciones = this.pregunta.opciones.filter((opcion) => {
        return opcion.correcto === false;
      });
    }
  }

  getCantidadOpciones(pregunta) {
    let counter = 0;
    this.pregunta.opciones.filter((opcion) => {
      if (opcion.correcto === true) {
        counter = counter + 1;
      }
    });
    console.log(counter);
    return counter;
  }

  createPregunta() {
    if (this.pregunta.pregunta.length === 0) {
      this.toastr.error('La pregunta no puede estar vacia', 'Pregunta vacia');
    } else {
      if (this.pregunta.opciones.length === 0 && this.pregunta.tipo_pregunta !== 'abierta') {
        this.toastr.error('La pregunta debe tener opciones de respuesta', 'Pregunta sin opciones');
      } else {
        this.test.preguntas.push(this.pregunta);
        this.resetPregunta();
      }
    }
  }
  createOption() {
    if (this.getCantidadOpciones(this.pregunta) === 1 && this.pregunta.tipo_pregunta === 'opcion_unica' && this.opcion.correcto === true) {
      this.toastr.error('Solo debe existir una opcion correcta', 'Error opcion unica');
    } else {
      if (this.pregunta.tipo_pregunta === 'dicotomica' && this.counter >= 2) {
        this.toastr.error('No puede agregar otra opción, el tipo de pregunta es dicotómica', 'Excedente de opciones');
      } else {
        if (this.opcion.descripcion.length === 0) {
          this.toastr.error('La descripción de la opción no debe ser vacia', 'Opcion vacia');
        } else {
          this.pregunta.opciones.push(this.opcion);
          this.resetOpcion();
          this.counter = this.counter + 1;
        }
      }
    }
  }
  cancel() {
    this.switch.emit(null);
  }

  setPregunta(pregunta, index) {
    this.pregunta = pregunta;
    this.test.preguntas.splice(index, 1);
  }
  store() {
    if (this.test.titulo.length > 0) {
      this.test.documentacion_en_linea_id = parseInt(this.settings.documentacion_en_linea_id, 10);
      this.testService.store(this.test)
        .subscribe((res: any) => {
          this.cancel();
          this.toastr.success('Test registrado exitosamente', 'Éxito');
          this.test = {
            documentacion_en_linea_id: null,
            titulo: '',
            descripcion: '',
            con_puntuacion: false,
            preguntas: []
          };
          this.pregunta = {
            pregunta: '',
            tipo_pregunta: 'opcion_unica',
            puntuacion: 0.00,
            opciones: []
          };
          this.opcion = {
            descripcion: '',
            correcto: false
          };
          this.reloadPage();
        });
    } else {
      this.toastr.error('El test debe tener un título', 'Error');
    }
  }

  removeOpcion(index) {
    this.counter = this.counter - 1;
    this.pregunta.opciones.splice(index, 1);
  }
}
