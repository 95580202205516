import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NoticiaService} from '../noticia.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../../environments/environment.prod';
import { faCalendarAlt, faHome, faFile, faImage } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-noticia-create',
  templateUrl: './noticia-create.component.html',
  styleUrls: ['./noticia-create.component.scss']
})
export class NoticiaCreateComponent implements OnInit {
  faImage = faImage;
  faFile = faFile;
  faYoutube = faYoutube;
  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  noticiaGroup: FormGroup;
  value = '';
  personal = null;
  editorConfig = null;
  spinnerSettings = environment.spinnerSettings;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authService: AuthService,
              private noticiaService: NoticiaService) {
    this.setConfigEditor();
    this.createForm();
  }

  setConfigEditor() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '450',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Ingrese el contenido aquí...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top'
    };
  }

  ngOnInit() {
    this.authService.currentAuth.subscribe((auth) => {
      this.personal = auth;
    });
  }

  createForm() {
    this.noticiaGroup = this.fb.group({
      titulo: new FormControl('', [Validators.required]),
      contenido: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      personal_id: new FormControl(0, [Validators.required]),
      tipo: new FormControl('', [Validators.required]),
      cc: new FormControl(false),
      nsp: new FormControl(false),
      mqmc: new FormControl(false),
      pacto: new FormControl(false),
      patrocinio: new FormControl(false),
      publico: new FormControl(false),
    });
  }

  validation() {
    let counter = 0;
    if (this.noticiaGroup.value.cc === 1 || this.noticiaGroup.value.cc === true) {
      counter++;
    }
    if (this.noticiaGroup.value.nsp === 1 || this.noticiaGroup.value.nsp === true) {
      counter++;
    }
    if (this.noticiaGroup.value.mqmc === 1 || this.noticiaGroup.value.mqmc === true) {
      counter++;
    }
    if (this.noticiaGroup.value.pacto === 1 || this.noticiaGroup.value.pacto === true) {
      counter++;
    }
    if (this.noticiaGroup.value.patrocinio === 1 || this.noticiaGroup.value.patrocinio === true) {
      counter++;
    }
    if (this.noticiaGroup.value.publico === 1 || this.noticiaGroup.value.publico === true) {
      counter++;
    }
    return counter !== 0;
  }

  getTopics() {
    const topics = [];
    if (this.noticiaGroup.value.cc) {
//      topics.push('/topics/menores_5'); - ahora 0-5
      topics.push('/topics/cc');
    }
    if (this.noticiaGroup.value.nsp) {
//      topics.push('/topics/entre_6_14'); - ahora 6-11
      topics.push('/topics/nsp');
    }
    if (this.noticiaGroup.value.mqmc) {
//      topics.push('/topics/me_quiero_me_cuido'); - ahora 12-15
      topics.push('/topics/mqmc');
    }
    if (this.noticiaGroup.value.pacto) {
//      topics.push('/topics/entre_15_24'); - ahora 16-24
      topics.push('/topics/pacto');
    }
    if (this.noticiaGroup.value.patrocinio) {
      topics.push('/topics/patrocinio');
    }
    if (this.noticiaGroup.value.publico) {
      topics.push('/topics/publico');
    }
    return topics;
  }

  sendNotifications(noticiaId: number) {
    const topics = this.getTopics();
    topics.forEach((topic: string) => {
      const data = {
        to: topic,
        data: {
          titulo: this.noticiaGroup.value.titulo,
          date: this.noticiaGroup.value.date,
          contenido: this.noticiaGroup.value.contenido,
          tipo_publicacion: 'noticia',
          publicacion_id: noticiaId
        }
      };
      this.noticiaService.sendNotification(data)
        .subscribe((confirmacion: any) => {
          console.log(confirmacion);
        });
    });
  }

  store() {
    this.noticiaGroup.patchValue({
      personal_id: this.personal.personal_id
    });
    if (this.validation() === true) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.noticiaService.store(this.noticiaGroup.value)
        .subscribe((res: any) => {
          this.sendNotifications(res.noticia_id);
          this.spinner.hide('spinner');
          this.toastr.success('Noticia registrada', 'Éxito');
          this.router.navigate(['/admin/noticias']);
        }, (error: any) => {
          this.spinner.hide('spinner');
          this.toastr.error('Se recomienda quitar algunas imágenes y agregarlas por separado', 'Contenido muy largo');
        });
    } else {
      this.toastr.info('Por favor registre al menos un programa', 'Revisar');
    }
  }
}
