import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  base = environment.base;
  constructor(private http: HttpClient) { }

  get(data: any) {
    return this.http.post(`${this.base}multimedia-get`, data);
  }
  store(data: any) {
    return this.http.post(`${this.base}multimedia`, data);
  }
  destroy(multimediaId: number) {
    return this.http.delete(`${this.base}multimedia/${multimediaId}`);
  }
}
