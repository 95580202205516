import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NoticiaService} from '../noticia.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'app-noticia-edit',
  templateUrl: './noticia-edit.component.html',
  styleUrls: ['./noticia-edit.component.scss']
})
export class NoticiaEditComponent implements OnInit {
  noticia: any = null;
  noticiaId: number = null;
  noticiaGroup: FormGroup;
  editorConfig = null;
  spinnerSettings = environment.spinnerSettings;
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private noticiaService: NoticiaService) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.setEditorConfig();
    this.route.params.subscribe((param: any) => {
      this.noticiaId = param.id;
      this.noticiaService.show(param.id)
        .subscribe((noticia: any) => {
          this.noticia = noticia;
          this.createForm(noticia);
          this.spinner.hide('spinner');
        });
    });
  }

  setEditorConfig() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '500',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top',
    };
  }
  ngOnInit() {
  }

  validation() {
    let counter = 0;
    if (this.noticiaGroup.value.menores_5 === 1 || this.noticiaGroup.value.menores_5 === true) {
      counter++;
    }
    if (this.noticiaGroup.value.entre_6_14 === 1 || this.noticiaGroup.value.entre_6_14 === true) {
      counter++;
    }
    if (this.noticiaGroup.value.entre_15_24 === 1 || this.noticiaGroup.value.entre_15_24 === true) {
      counter++;
    }
    if (this.noticiaGroup.value.me_quiero_me_cuido === 1 || this.noticiaGroup.value.me_quiero_me_cuido === true) {
      counter++;
    }
    if (this.noticiaGroup.value.patrocinio === 1 || this.noticiaGroup.value.patrocinio === true) {
      counter++;
    }
    if (this.noticiaGroup.value.publico === 1 || this.noticiaGroup.value.publico === true) {
      counter++;
    }
    return counter !== 0;
  }

  createForm(noticia: any) {
    this.noticiaGroup = this.fb.group({
      titulo: new FormControl(noticia.titulo, [Validators.required]),
      date: new FormControl(noticia.date, [Validators.required]),
      contenido: new FormControl(noticia.contenido, [Validators.required]),
      tipo: new FormControl(noticia.tipo, [Validators.required]),
      personal_id: new FormControl(parseInt(noticia.personal_id, 10), [Validators.required]),
      menores_5: new FormControl(parseInt(noticia.menores_5, 10)),
      entre_6_14: new FormControl(parseInt(noticia.entre_6_14, 10)),
      entre_15_24: new FormControl(parseInt(noticia.entre_15_24, 10)),
      me_quiero_me_cuido: new FormControl(parseInt(noticia.me_quiero_me_cuido, 10)),
      patrocinio: new FormControl(parseInt(noticia.patrocinio, 10)),
      publico: new FormControl(parseInt(noticia.publico, 10)),
    });
  }

  update() {
    if (this.validation() === true) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.noticiaService.update(this.noticiaGroup.value, this.noticiaId)
        .subscribe((res: any) => {
          this.spinner.hide('spinner');
          this.toastr.success('Noticia actualizada', 'Éxito');
          this.router.navigate(['/admin/noticias']);
        }, (error: any) => {
          this.spinner.hide('spinner');
          this.toastr.error('Se recomienda quitar algunas imágenes y agregarlas por separado', 'Contenido muy largo');
        });
    } else {
      this.toastr.info('Por favor registre al menos un programa', 'Revisar');
    }
  }
}
