import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedRoutingModule} from './shared-routing.module';
import {YoutubeService} from './youtube.service';
import {SearchBoxComponent} from './search-box/search-box.component';
import {SearchResultComponent} from './search-result/search-result.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SafePipe} from './safe.pipe';
import {NoticiaItemComponent} from './noticia-item/noticia-item.component';
import {NoticiaContentComponent} from './noticia-content/noticia-content.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MultimediaIndexComponent} from './multimedia-index/multimedia-index.component';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {HorarioCreateComponent} from './horario-create/horario-create.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HorarioIndexComponent} from './horario-index/horario-index.component';
import {MultimediaCreateComponent} from './multimedia-create/multimedia-create.component';
import {TestCreateComponent} from './test-create/test-create.component';
import {TestIndexComponent} from './test-index/test-index.component';
import {NgbModalModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TestStatsComponent} from './test-stats/test-stats.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {RevisionModalComponent} from './revision-modal/revision-modal.component';
import {InscripcionModalComponent} from './inscripcion-modal/inscripcion-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ViewerComponent} from './viewer/viewer.component';
import {BeneficiarioModalComponent} from './beneficiario-modal/beneficiario-modal.component';
import {AgmCoreModule} from '@agm/core';

@NgModule({
  declarations: [SearchBoxComponent,
    SearchResultComponent,
    SafePipe,
    NoticiaItemComponent,
    NoticiaContentComponent,
    MultimediaIndexComponent,
    HorarioCreateComponent,
    HorarioIndexComponent,
    MultimediaCreateComponent,
    TestCreateComponent,
    TestIndexComponent,
    TestStatsComponent,
    RevisionModalComponent,
    InscripcionModalComponent,
    ViewerComponent,
    BeneficiarioModalComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    GoogleChartsModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgbTooltipModule,
    NgbModalModule,
    NgSelectModule,
    NgbTabsetModule,
    AgmCoreModule
  ],
  providers: [
    YoutubeService
  ],
  exports: [
    SearchBoxComponent,
    SearchResultComponent,
    SafePipe,
    NoticiaItemComponent,
    NoticiaContentComponent,
    MultimediaIndexComponent,
    HorarioCreateComponent,
    HorarioIndexComponent,
    MultimediaCreateComponent,
    TestCreateComponent,
    TestIndexComponent,
    TestStatsComponent,
    RevisionModalComponent,
    InscripcionModalComponent
  ]
})
export class SharedModule {
}
