import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../auth.service';
import {DocumentacionService} from '../documentacion.service';
import {environment} from '../../../../environments/environment.prod';
import {Multimedia} from '../../../models/multimedia';
import {MultimediaService} from '../../multimedia.service';

@Component({
  selector: 'app-documentacion-create',
  templateUrl: './documentacion-create.component.html',
  styleUrls: ['./documentacion-create.component.scss']
})
export class DocumentacionCreateComponent implements OnInit {

  documentacionGroup: FormGroup;
  value = '';
  personal = null;
  editorConfig = null;
  spinnerSettings = environment.spinnerSettings;
  imagenesMultimedia: Multimedia[];
  imagenes: File[] = [];

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authService: AuthService,
              private multimediaService: MultimediaService,
              private documentacionService: DocumentacionService) {
    this.setConfigEditor();
    this.createForm();
  }

  setConfigEditor() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '450',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Ingrese el contenido aquí...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top'
    };
  }

  ngOnInit() {
    this.authService.currentAuth.subscribe((auth) => {
      this.personal = auth;
    });
  }

  createForm() {
    this.documentacionGroup = this.fb.group({
      titulo: new FormControl('', [Validators.required]),
      contenido: new FormControl('', [Validators.required]),
      personal_id: new FormControl(0, [Validators.required]),
      link: new FormControl(null),
      menores_5: new FormControl(false),
      entre_6_14: new FormControl(false),
      entre_15_24: new FormControl(false),
      me_quiero_me_cuido: new FormControl(false),
      patrocinio: new FormControl(false),
      publico: new FormControl(false),
    });
  }

  validation() {
    let counter = 0;
    if (this.documentacionGroup.value.menores_5 === 1 || this.documentacionGroup.value.menores_5 === true) {
      counter++;
    }
    if (this.documentacionGroup.value.entre_6_14 === 1 || this.documentacionGroup.value.entre_6_14 === true) {
      counter++;
    }
    if (this.documentacionGroup.value.entre_15_24 === 1 || this.documentacionGroup.value.entre_15_24 === true) {
      counter++;
    }
    if (this.documentacionGroup.value.me_quiero_me_cuido === 1 || this.documentacionGroup.value.me_quiero_me_cuido === true) {
      counter++;
    }
    if (this.documentacionGroup.value.patrocinio === 1 || this.documentacionGroup.value.patrocinio === true) {
      counter++;
    }
    if (this.documentacionGroup.value.publico === 1 || this.documentacionGroup.value.publico === true) {
      counter++;
    }
    return counter !== 0;
  }

  getTopics() {
    const topics = [];
    if (this.documentacionGroup.value.menores_5) {
      topics.push('/topics/menores_5');
    }
    if (this.documentacionGroup.value.entre_6_14) {
      topics.push('/topics/entre_6_14');
    }
    if (this.documentacionGroup.value.entre_15_24) {
      topics.push('/topics/entre_15_24');
    }
    if (this.documentacionGroup.value.me_quiero_me_cuido) {
      topics.push('/topics/me_quiero_me_cuido');
    }
    if (this.documentacionGroup.value.patrocinio) {
      topics.push('/topics/patrocinio');
    }
    if (this.documentacionGroup.value.publico) {
      topics.push('/topics/publico');
    }
    return topics;
  }

  sendNotifications(documentacionId: number) {
    const topics = this.getTopics();
    topics.forEach((topic: string) => {
      const data = {
        to: topic,
        data: {
          titulo: this.documentacionGroup.value.titulo,
          contenido: this.documentacionGroup.value.contenido,
          tipo_publicacion: 'documentacion_en_linea',
          link: this.documentacionGroup.value.link,
          publicacion_id: documentacionId
        }
      };
      this.documentacionService.sendNotification(data)
        .subscribe((confirmacion: any) => {
          console.log(confirmacion);
        });
    });
  }

  store() {
    this.documentacionGroup.patchValue({
      personal_id: this.personal.personal_id
    });
    if (this.validation() === true) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.documentacionService.store(this.documentacionGroup.value)
        .subscribe((res: any) => {
          this.sendNotifications(res.documentacion_en_linea_id);
          this.imagenes.map((imagen: File) => {
            /*this.spinner.show('spinner', this.spinnerSettings);*/
            const formData = new FormData();
            formData.append('file', imagen);
            formData.append('publicacion_id', res.documentacion_en_linea_id);
            formData.append('tipo_publicacion', 'documentacion_en_linea');
            formData.append('tipo_multimedia', 'imagen');
            formData.append('titulo', '');
            this.multimediaService.store(formData)
              .subscribe((multimedia: Multimedia) => {
                this.toastr.info(`Imagen subida`);
                /*this.spinner.hide('spinner');*/
              }, () => {
                /*this.spinner.hide('spinner');*/
              });
          });
          this.spinner.hide('spinner');
          this.toastr.success('Aula registrada', 'Éxito');
          this.router.navigate(['/admin/aulas/listar']);
        }, (error: any) => {
          this.spinner.hide('spinner');
          this.toastr.error('Se recomienda quitar algunas imágenes y agregarlas por separado', 'Contenido muy largo');
        });
    } else {
      this.toastr.info('Por favor registre al menos un programa', 'Revisar');
    }
  }

  uploadImagenes(event) {
    Array.from(event.target.files).forEach((imagen: File) => {
      this.imagenes.push(imagen);
    });
    console.log(this.imagenes);
  }

  removeImagen(index: number) {
    this.imagenes.splice(index, 1);
  }

}
