import {Component, OnInit} from '@angular/core';
import {HorarioService} from '../horario.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {EventoService} from '../../admin/evento/evento.service';
import {environment} from '../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-horario-create',
  templateUrl: './horario-create.component.html',
  styleUrls: ['./horario-create.component.scss']
})
export class HorarioCreateComponent implements OnInit {

  eventoId: number = null;
  evento: any = null;
  horarioGroup: FormGroup;
  spinnerSettings = environment.spinnerSettings;
  color = environment.spinnerSettings.bdColor;

  constructor(private horarioService: HorarioService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private eventoService: EventoService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {}

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.route.params.subscribe((param: any) => {
      this.eventoId = param.evento_id;
      this.eventoService.show(this.eventoId)
        .subscribe((evento: any) => {
          this.evento = evento;
          this.horarioGroup = this.fb.group({
            evento_id: new FormControl(this.eventoId, [Validators.required]),
            evento: new FormControl(this.evento.titulo, [Validators.required]),
            inicio: new FormControl('', [Validators.required]),
            fin: new FormControl('', [Validators.required]),
            lugar: new FormControl('', [Validators.required]),
            direccion: new FormControl('', [Validators.required]),
            cupo_maximo: new FormControl(0, [Validators.required]),
            cupo_lleno: new FormControl(0, [Validators.required]),
          });
          this.spinner.hide('spinner');
        });
    });
  }

  store() {
    this.horarioService.store(this.horarioGroup.value)
      .subscribe((res: any) => {
        this.toastr.success('Horario registrado', 'Éxito');
        this.router.navigate([`/admin/eventos/mostrar/${this.eventoId}`]);
      });
  }
}
