import { Component, OnInit } from '@angular/core';
import {faCalendarAlt, faFile, faFileContract, faHome, faImage, faPlay} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {DocumentacionService} from '../documentacion.service';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-documentacion-show',
  templateUrl: './documentacion-show.component.html',
  styleUrls: ['./documentacion-show.component.scss']
})
export class DocumentacionShowComponent implements OnInit {
    aula = null;
    faImage = faImage;
    faFile = faFile;
    faPlay = faPlay;
    faYoutube = faYoutube;
    faFileContract = faFileContract;
    faHome = faHome;
    faCalendarAlt = faCalendarAlt;
    color = environment.spinnerSettings.bdColor;

    spinnerSettings = environment.spinnerSettings;
    imagenSettings = null;
    archivoSettings = null;
    youtubeSettings = null;
    testSettings = null;
    testCreateSettings = null;

    multimediaSettings = null;
    constructor(private documentacionService: DocumentacionService,
                private spinner: NgxSpinnerService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.spinner.show('spinner', this.spinnerSettings);
        this.route.params.subscribe((param: any) => {
            this.documentacionService.show(param.id)
                .subscribe((aula: any) => {
                    this.aula = aula;
                    this.imagenSettings = {
                        publicacion_id: aula.documentacion_en_linea_id,
                        tipo_publicacion: 'documentacion_en_linea',
                        tipo_multimedia: 'imagen',
                        acciones: true
                    };
                    this.archivoSettings = {
                        publicacion_id: aula.documentacion_en_linea_id,
                        tipo_publicacion: 'documentacion_en_linea',
                        tipo_multimedia: 'archivo',
                        acciones: true
                    };
                    this.youtubeSettings = {
                        publicacion_id: aula.documentacion_en_linea_id,
                        tipo_publicacion: 'documentacion_en_linea',
                        tipo_multimedia: 'youtube',
                        acciones: true
                    };
                    this.testSettings = {
                        documentacion_en_linea_id: aula.documentacion_en_linea_id,
                        acciones: true
                    };
                    this.spinner.hide('spinner');
                });
        });
    }

    createTest() {
        this.multimediaSettings = null;
        this.testCreateSettings = {
            documentacion_en_linea_id: this.aula.documentacion_en_linea_id
        };
    }
    createMultimedia(tipoMultimedia: string) {
        this.testCreateSettings = null;
        this.multimediaSettings = {
            publicacion_id: this.aula.documentacion_en_linea_id,
            publicacion: this.aula.titulo,
            tipo_publicacion: 'documentacion_en_linea',
            tipo_multimedia: tipoMultimedia
        };
    }
}
