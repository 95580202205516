import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  faUserFriends,
  faUserShield,
  faUserCircle,
  faFile,
  faMailBulk,
  faAward,
  faBars,
  faGlobeAmericas,
  faBell,
  faComments,
  faChalkboardTeacher,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import {AuthService} from '../auth.service';
import {environment} from '../../environments/environment.prod';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  faUserShield = faUserShield;
  faUserFriends = faUserFriends;
  faUserCircle = faUserCircle;
  faFile = faFile;
  faMailBulk = faMailBulk;
  faAward = faAward;
  faBars = faBars;
  faGlobeAmericas = faGlobeAmericas;
  faBell = faBell;
  faComments = faComments;
  faChalkboardTeacher = faChalkboardTeacher;
  faNewspaper = faNewspaper;

  color = environment.spinnerSettings.bdColor;
  personal = null;
  usuario: any = null;

  constructor(public router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.getUser()
      .subscribe((res: any) => {
        this.usuario = res.success;
      });
    this.authService.getPersonal()
      .subscribe((res: any) => {
        if (res.success === null || res.error) {
          this.authService.logout()
            .subscribe((loggedOut) => {
              if (loggedOut) {
                this.router.navigate(['/invitado']);
              }
            });
        } else {
          this.personal = res.success;
          this.authService.setAuth(res.success);
        }
      });
    $(document).ready(() => {
      $('#menu-toggle').click((e) => {
        e.preventDefault();
        $('#wrapper').toggleClass('toggled');
      });
      $('#dropdown').click(function (e) {
        e.preventDefault();
        this.toggle = !this.toggle;
        if (this.toggle) {
          $('#dropdown-content').slideDown(200);
        } else {
          $('#dropdown-content').slideUp(200);
        }
      });
    });
  }

  logout() {
    this.authService.logout()
      .subscribe((res) => {
        console.log(res);
        if (res === true) {
          this.router.navigate(['/invitado']);
        }
      });
  }

}
