import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  paginate(items: number) {
    return this.http.get(`${this.base}personal-paginate/${items}`);
  }

  paginateURL(url: string) {
    return this.http.get(url);
  }

  search(data: any) {
    return this.http.post(`${this.base}personal-search`, data);
  }

  store(data: any) {
    return this.http.post(`${this.base}personal`, data);
  }

  destroy(id) {
    return this.http.delete(`${this.base}personal/${id}`);
  }

  show(id) {
    return this.http.get(`${this.base}personal/${id}`);
  }

  update(data, id) {
    return this.http.put(`${this.base}personal/${id}`, data);
  }
}
