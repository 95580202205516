export class RequestActividad {
  constructor(
    public valor: string = '',
    public items: number = 10,
    public modelo_programatico: 'cc' | 'nsp' | 'mqmc' | 'pacto' | 'patrocinio' = 'patrocinio',
    public gestion: number = new Date().getFullYear(),
    public mes: number = new Date().getMonth() + 1,
    public page: number = 1
  ) {
  }
}
