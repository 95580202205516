import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-pacto',
  templateUrl: './pacto.component.html',
  styleUrls: ['./pacto.component.scss']
})
export class PactoComponent implements OnInit {

    images = [
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-1.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-2.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-3.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-4.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-5.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-6.jpg',
            title: 'titulo',
            description: 'descripcion'
        },
        {
            src: '../../../assets/img/landing_page/pacto/PACTO-7.jpg',
            title: 'titulo',
            description: 'descripcion'
        }
    ];
  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
