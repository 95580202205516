import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ComunitarioService} from '../comunitario.service';

@Component({
    selector: 'app-comunitario-edit',
    templateUrl: './comunitario-edit.component.html',
    styleUrls: ['./comunitario-edit.component.scss']
})
export class ComunitarioEditComponent implements OnInit {

    comunitarioGroup: FormGroup;
    comunitario = null;
    comunitarioId = null;
    constructor(private fb: FormBuilder,
                private toastr: ToastrService,
                private router: Router,
                private comunitarioService: ComunitarioService,
                private route: ActivatedRoute) {
        this.route.params.subscribe((param: any) => {
            this.comunitarioId = param.id;
            this.comunitarioService.show(param.id)
                .subscribe((com) => {
                    this.comunitario = com;
                    this.createForm();
                });
        });
    }

    ngOnInit() {
    }

    createForm() {
        this.comunitarioGroup = this.fb.group({
            child_number : new FormControl(this.comunitario.child_number, [Validators.required]),
            nombre_completo : new FormControl(this.comunitario.nombre_completo, [Validators.required]),
            fecha_nacimiento : new FormControl(this.comunitario.fecha_nacimiento, [Validators.required]),
            sexo : new FormControl(this.comunitario.sexo, [Validators.required]),
            telefono_celular : new FormControl(this.comunitario.telefono_celular),
            nombre_tutor : new FormControl(this.comunitario.nombre_tutor),
            telefono_celular_tutor : new FormControl(this.comunitario.telefono_celular_tutor),
            direccion : new FormControl(this.comunitario.direccion),
            unidad_educativa_universidad : new FormControl(this.comunitario.unidad_educativa_universidad),
            curso : new FormControl(this.comunitario.curso)
        });
    }

    update() {
        this.comunitarioService.update(this.comunitarioId, this.comunitarioGroup.value)
            .subscribe((res: any) => {
                if (res.error) {
                    this.toastr.error( res.error, 'No se pudo actualizar la información');
                } else {
                    this.router.navigate(['/admin/comunitarios/listar']);
                    this.toastr.success(`${this.comunitarioGroup.value.nombre_completo} actualizado`, 'Actualización exitosa');
                }
            });
    }

}
