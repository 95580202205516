export class Personal {
  constructor(
    public usuario_id: number,
    public nombres: string,
    public apellidos: string,
    public numero_carnet: string,
    public telefono_celular: string,
    public tipo_personal: 'tecnico' | 'supervisor' | 'administrador',
    public cuenta?: string
  ) {
  }
}
