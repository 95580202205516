import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BeneficiarioService} from '../beneficiario.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-beneficiario-edit',
    templateUrl: './beneficiario-edit.component.html',
    styleUrls: ['./beneficiario-edit.component.scss']
})
export class BeneficiarioEditComponent implements OnInit {

    beneficiarioGroup: FormGroup;
    beneficiario = null;
    beneficiarioId = null;
    constructor(private fb: FormBuilder,
                private toastr: ToastrService,
                private router: Router,
                private beneficiarioService: BeneficiarioService,
                private route: ActivatedRoute) {
        this.route.params.subscribe((param: any) => {
            this.beneficiarioId = param.id;
            this.beneficiarioService.show(param.id)
                .subscribe((benef) => {
                        this.beneficiario = benef;
                        this.createForm();
                    });
        });
    }

    ngOnInit() {
    }

    createForm() {
        this.beneficiarioGroup = this.fb.group({
            child_number : new FormControl(this.beneficiario.child_number, [Validators.required]),
            nombre_completo : new FormControl(this.beneficiario.nombre_completo, [Validators.required]),
            fecha_nacimiento : new FormControl(this.beneficiario.fecha_nacimiento, [Validators.required]),
            sexo : new FormControl(this.beneficiario.sexo, [Validators.required]),
            numero_caso : new FormControl(this.beneficiario.numero_caso),
            telefono_celular : new FormControl(this.beneficiario.telefono_celular),
            nombre_tutor : new FormControl(this.beneficiario.nombre_tutor),
            telefono_celular_tutor : new FormControl(this.beneficiario.telefono_celular_tutor),
            direccion : new FormControl(this.beneficiario.direccion),
            unidad_educativa_universidad : new FormControl(this.beneficiario.unidad_educativa_universidad),
            curso : new FormControl(this.beneficiario.curso)
        });
    }

    update() {
        this.beneficiarioService.update(this.beneficiarioId, this.beneficiarioGroup.value)
            .subscribe((res: any) => {
                if (res.error) {
                    this.toastr.error( res.error, 'No se pudo actualizar la información');
                } else {
                    this.router.navigate(['/admin/beneficiarios/listar']);
                    this.toastr.success(`${this.beneficiarioGroup.value.nombre_completo} actualizado`, 'Actualización exitoso');
                }
            });
    }

}
