import {Component, OnInit} from '@angular/core';
import {faKey, faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  faUser = faUser;
  faKey = faKey;
  faLock = faLock;
  loginGroup: FormGroup;
  urlYoutube = 'https://www.youtube.com/embed/MC3mXLZB9yA?rel=0&controls=0&showinfo=0&autoplay=1&html5=1&allowfullscreen=true&wmode=transparent&loop=1';

  constructor(private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.loginGroup = this.fb.group({
      cuenta: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    this.spinner.hide();
  }

  login() {
    this.loginGroup.patchValue({
      cuenta: this.loginGroup.get('cuenta').value.toLowerCase()
    });
    this.authService.login(this.loginGroup.value)
      .subscribe(() => {
        this.authService.getUser()
          .subscribe((res: any) => {
            this.toastr.success('Accediendo al sistema', 'Cargando...');
            switch (res.success.tipo_usuario) {
              case 'beneficiario':
                this.router.navigate(['/usuario']);
                break;
              case 'administrador':
              case 'supervisor':
              case 'tecnico':
                this.router.navigate(['/admin']);
                break;
            }
          });
      }, () => {
        this.toastr.error('Las credenciales son incorrectas', 'No autenticado');
      });
  }

}
