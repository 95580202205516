import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {NgxSpinnerService} from 'ngx-spinner';
import {faEdit, faPlus, faSearch, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ZonaService} from '../../../services/zona.service';
import {Zona} from '../../../models/zona';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-zona-index',
  templateUrl: './zona-index.component.html',
  styleUrls: ['./zona-index.component.scss']
})
export class ZonaIndexComponent implements OnInit {

  faSearch = faSearch;
  faEdit = faEdit;
  faTrash = faTrash;
  faPlus = faPlus;
  items = 10;
  zonas: any = null;
  valor = '';
  spinnerSettings = environment.spinnerSettings;
  color = '#16874F';

  constructor(private zonaService: ZonaService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    this.spinnerSettings.color = '#16874FEE';
  }

  ngOnInit() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.zonaService.filtrar(this.valor)
      .subscribe((zonas: any[]) => {
        this.zonas = zonas;
        this.spinner.hide('spinner');
      });
  }

  search() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.zonaService.filtrar(this.valor)
      .subscribe((zonas: any[]) => {
        this.zonas = zonas;
        this.spinner.hide('spinner');
      });
  }

  destroy(zona: Zona) {
    const message = 'Esta seguro de eliminar la zona ' + zona.nombre;
    if (confirm(message)) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.zonaService.destroy(zona.zona_id)
        .subscribe((res: any) => {
          if (res.response) {
            this.toastr.success(`${res.message}`, 'Éxito');
            this.zonas = this.zonas.filter((item: Zona) => {
              return item.zona_id !== zona.zona_id;
            });
            this.spinner.hide('spinner');
          } else {
            this.toastr.error(`${res.message}`, 'Error');
            this.spinner.hide('spinner');
          }
        });
    }
  }

}
