import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {
  faFlag,faEye, faStar
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-nuestros-valores',
  templateUrl: './nuestros-valores.component.html',
  styleUrls: ['./nuestros-valores.component.scss']
})
export class NuestrosValoresComponent implements OnInit {

  faFlag = faFlag;
  faEye = faEye;
  faStar= faStar;
  
  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

}
