import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {AdminComponent} from './admin.component';
import {BeneficiarioComponent} from './beneficiario/beneficiario.component';
import {BeneficiarioUploadComponent} from './beneficiario/beneficiario-upload/beneficiario-upload.component';
import {BeneficiarioIndexComponent} from './beneficiario/beneficiario-index/beneficiario-index.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BeneficiarioCreateComponent} from './beneficiario/beneficiario-create/beneficiario-create.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BeneficiarioService} from './beneficiario/beneficiario.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PersonalComponent} from './personal/personal.component';
import {PersonalIndexComponent} from './personal/personal-index/personal-index.component';
import {PersonalCreateComponent} from './personal/personal-create/personal-create.component';
import {PersonalEditComponent} from './personal/personal-edit/personal-edit.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NoticiaComponent} from './noticia/noticia.component';
import {NoticiaIndexComponent} from './noticia/noticia-index/noticia-index.component';
import {NoticiaCreateComponent} from './noticia/noticia-create/noticia-create.component';
import {NoticiaEditComponent} from './noticia/noticia-edit/noticia-edit.component';
import {NoticiaService} from './noticia/noticia.service';
import {NoticiaShowComponent} from './noticia/noticia-show/noticia-show.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {MultimediaService} from './multimedia.service';
import {SharedModule} from '../shared/shared.module';
import {Interceptor} from '../interceptor';
import {EventoComponent} from './evento/evento.component';
import {EventoCreateComponent} from './evento/evento-create/evento-create.component';
import {EventoEditComponent} from './evento/evento-edit/evento-edit.component';
import {EventoIndexComponent} from './evento/evento-index/evento-index.component';
import {EventoShowComponent} from './evento/evento-show/evento-show.component';
import {AgmCoreModule} from '@agm/core';
import {DocumentacionComponent} from './documentacion/documentacion.component';
import {DocumentacionIndexComponent} from './documentacion/documentacion-index/documentacion-index.component';
import {DocumentacionCreateComponent} from './documentacion/documentacion-create/documentacion-create.component';
import {DocumentacionEditComponent} from './documentacion/documentacion-edit/documentacion-edit.component';
import {DocumentacionShowComponent} from './documentacion/documentacion-show/documentacion-show.component';
import {NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ComunitarioComponent} from './comunitario/comunitario.component';
import {ComunitarioCreateComponent} from './comunitario/comunitario-create/comunitario-create.component';
import {ComunitarioIndexComponent} from './comunitario/comunitario-index/comunitario-index.component';
import {BeneficiarioEditComponent} from './beneficiario/beneficiario-edit/beneficiario-edit.component';
import {ComunitarioEditComponent} from './comunitario/comunitario-edit/comunitario-edit.component';
import {RevisionModalComponent} from '../shared/revision-modal/revision-modal.component';
import {CapacitacionComponent} from './capacitacion/capacitacion.component';
import {CapacitacionCreateComponent} from './capacitacion/capacitacion-create/capacitacion-create.component';
import {CapacitacionIndexComponent} from './capacitacion/capacitacion-index/capacitacion-index.component';
import {CorrespondenciaComponent} from './correspondencia/correspondencia.component';
import {CorrespondenciaIndexComponent} from './correspondencia/correspondencia-index/correspondencia-index.component';
import {CorrespondenciaImportComponent} from './correspondencia/correspondencia-import/correspondencia-import.component';
import {ComunicadoComponent} from './comunicado/comunicado.component';
import {ComunicadoCreateComponent} from './comunicado/comunicado-create/comunicado-create.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ZonaComponent} from './zona/zona.component';
import {ZonaCreateComponent} from './zona/zona-create/zona-create.component';
import {ZonaIndexComponent} from './zona/zona-index/zona-index.component';
import {ZonaEditComponent} from './zona/zona-edit/zona-edit.component';
import {ActividadComponent} from './actividad/actividad.component';
import {ActividadIndexComponent} from './actividad/actividad-index/actividad-index.component';
import {ActividadCreateComponent} from './actividad/actividad-create/actividad-create.component';
import {ActividadEditComponent} from './actividad/actividad-edit/actividad-edit.component';
import {ComunicadoIndexComponent} from './comunicado/comunicado-index/comunicado-index.component';
import {ViewerComponent} from '../shared/viewer/viewer.component';
import {BeneficiarioModalComponent} from '../shared/beneficiario-modal/beneficiario-modal.component';

@NgModule({
  declarations: [
    BeneficiarioComponent,
    BeneficiarioUploadComponent,
    BeneficiarioIndexComponent,
    AdminComponent,
    BeneficiarioCreateComponent,
    PersonalComponent,
    PersonalIndexComponent,
    PersonalCreateComponent,
    PersonalEditComponent,
    NoticiaComponent,
    NoticiaIndexComponent,
    NoticiaCreateComponent,
    NoticiaEditComponent,
    NoticiaShowComponent,
    EventoComponent,
    EventoCreateComponent,
    EventoEditComponent,
    EventoIndexComponent,
    EventoShowComponent,
    DocumentacionComponent,
    DocumentacionIndexComponent,
    DocumentacionCreateComponent,
    DocumentacionEditComponent,
    DocumentacionShowComponent,
    ComunitarioComponent,
    ComunitarioCreateComponent,
    ComunitarioIndexComponent,
    BeneficiarioEditComponent,
    ComunitarioEditComponent,
    CapacitacionComponent,
    CapacitacionCreateComponent,
    CapacitacionIndexComponent,
    CorrespondenciaComponent,
    CorrespondenciaIndexComponent,
    CorrespondenciaImportComponent,
    ComunicadoComponent,
    ComunicadoCreateComponent,
    ZonaComponent,
    ZonaCreateComponent,
    ZonaIndexComponent,
    ZonaEditComponent,
    ActividadComponent,
    ActividadIndexComponent,
    ActividadCreateComponent,
    ActividadEditComponent,
    ComunicadoIndexComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AngularEditorModule,
    NgbTooltipModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCToQLtMIp3uga5g6uf8pPkt9l2jH2pWvs',
      libraries: ['geometry']
    }),
    NgSelectModule,
    NgbTabsetModule
  ],
  entryComponents: [RevisionModalComponent, ViewerComponent, BeneficiarioModalComponent],
  providers: [
    BeneficiarioService,
    NoticiaService,
    MultimediaService,
    {
      provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
    }
  ]
})
export class AdminModule {
}
