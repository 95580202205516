import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ZonaService} from '../../../services/zona.service';

@Component({
  selector: 'app-zona-edit',
  templateUrl: './zona-edit.component.html',
  styleUrls: ['./zona-edit.component.scss']
})
export class ZonaEditComponent implements OnInit {
  zonaId: number = null;
  zona: any = null;
  zonaGroup: FormGroup;
  spinnerSettings = environment.spinnerSettings;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private zonaService: ZonaService) {
    this.spinner.show('spinner', this.spinnerSettings);
    this.route.params.subscribe((param: any) => {
      this.zonaId = param.id;
      this.zonaService.show(param.id)
        .subscribe((zona: any) => {
          this.zona = zona;
          this.createForm();
          this.spinner.hide('spinner');
        });
    });
  }

  ngOnInit() {
  }

  createForm() {
    this.zonaGroup = this.fb.group({
      zona_id: new FormControl(this.zona.zona_id, [Validators.required]),
      nombre: new FormControl(this.zona.nombre, [Validators.required]),
      codigo: new FormControl(this.zona.codigo, [Validators.required]),
      direccion: new FormControl(this.zona.direccion),
      latitud: new FormControl(this.zona.latitud),
      longitud: new FormControl(this.zona.longitud),
    });
  }

  update() {
    this.spinner.show('spinner', this.spinnerSettings);
    this.zonaService.update(this.zonaGroup.value)
      .subscribe((res: any) => {
        this.spinner.hide('spinner');
        this.toastr.success('Zona actualizada', 'Éxito');
        this.router.navigate(['/admin/zonas']);
      }, (error: any) => {
        this.spinner.hide('spinner');
        this.toastr.error('Ocurrió un error al actualizar la zona', 'Intente nuevamente');
      });
  }

}
