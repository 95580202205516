import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenciaService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  correspondenciasLoteSlip(lote_id: number, mail_control_slip: string) {
    const params = new HttpParams()
      .set('lote_id', lote_id.toString())
      .set('mail_control_slip', mail_control_slip);

    return this.http.get(`${this.base}correspondencias-lote-slip`, {
      params
    });
  }

  store(data: any, tipo_correspondencia_id: number, descripcion: string, fecha_recepcion: string, fecha_limite_entrega: string) {
    const params = new HttpParams()
      .set('tipo_correspondencia_id', tipo_correspondencia_id.toString())
      .set('descripcion', descripcion)
      .set('fecha_recepcion', fecha_recepcion.toString())
      .set('fecha_limite_entrega', fecha_limite_entrega.toString());
    const request = new HttpRequest('POST', `${this.base}correspondencias`, {
      correspondencias: data
    }, {
      reportProgress: true,
      params
    });
    return this.http.request(request);
  }

  cambiarEstado(estado: string, correspondencia_id: number) {
    return this.http.put(`${this.base}cambiar-estado-correspondencia/${correspondencia_id}`, {
      estado_supervisor: estado
    });
  }

  estadisticos(lote_id: number) {
    return this.http.get(`${this.base}correspondencias-estadisticos/${lote_id}`);
  }

  uploadCorrespondenciasSponsor(formData: FormData, lote_id: number) {
    const params = new HttpParams()
      .set('lote_id', lote_id.toString());
    return this.http.post(`${this.base}upload-correspondencias-sponsor`, formData, {
      params
    });
  }

  uploadCorrespondenciasEscaneadas(formData: FormData, lote_id: number) {
    const params = new HttpParams()
      .set('lote_id', lote_id.toString());
    return this.http.post(`${this.base}upload-correspondencias-escaneadas`, formData, {
      params
    });
  }

  uploadSlips(formData: FormData, lote_id: number) {
    const params = new HttpParams()
      .set('lote_id', lote_id.toString());
    return this.http.post(`${this.base}upload-slips`, formData, {
      params
    });
  }

}
