import {Component, Input, OnInit} from '@angular/core';
import {MultimediaService} from '../multimedia.service';
import {faEdit, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-multimedia-index',
  templateUrl: './multimedia-index.component.html',
  styleUrls: ['./multimedia-index.component.scss']
})
export class MultimediaIndexComponent implements OnInit {

  @Input() settings: any;

  multimedia = null;
  imagen = null;
  faEdit = faEdit;
  faTrash = faTrash;
  faTimes = faTimes;
  spinnerSettings = environment.spinnerSettings;
  constructor(private multimediaService: MultimediaService,
              private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.multimediaService.get(this.settings)
      .subscribe((res: any) => {
        this.multimedia = res;
      });
  }

  reload() {
    this.multimediaService.get(this.settings)
      .subscribe((res: any) => {
        this.multimedia = res;
      });
  }
  select(imagen: any) {
    window.scrollTo(0, 0);
    this.imagen = imagen;
  }
  close() {
    this.imagen = null;
  }
  destroy(multimediaId: number) {
    if (confirm(`¿Esta seguro de eliminar el recurso?`)) {
      this.spinner.show('spinner', this.spinnerSettings);
      this.multimediaService.destroy(multimediaId)
        .subscribe((res: any) => {
          this.reload();
        });
    }
  }

}
