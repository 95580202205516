import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntegranteService {

  base = environment.base;

  constructor(private http: HttpClient) {
  }

  store(data) {
    return this.http.post(`${this.base}integrantes`, data);
  }

  integrantesCapacitacion(capacitacion_id: number) {
    return this.http.get(`${this.base}integrantes-capacitacion/${capacitacion_id}`);
  }
}
