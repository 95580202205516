export class Capacitacion {

  constructor(
    public titulo: string,
    public facilitador: string,
    public fecha_inicio_inscripcion: string,
    public fecha_fin_inscripcion: string,
    public fecha_inicio_capacitacion: string,
    public fecha_fin_capacitacion: string,
    public modalidad: string,
    public tipo_capacitacion: string,
    public show: boolean = false,
    public capacitacion_id?: number,
    public visible?: boolean,
    public aviso?: string,
    public imagen?: string,
    public descripcion?: string,
    public lugar?: string,
    public latitud?: string,
    public longitud?: string,
    public enlace?: string,
  ) {
  }

}
