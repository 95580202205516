export class Zona {
  constructor(
    public nombre: string,
    public codigo: string,
    public direccion?: string,
    public latitud?: string,
    public longitud?: string,
    public zona_id?: number
  ) {
  }
}
