import {Injectable} from '@angular/core';
import {environment} from '../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  base = environment.base;
  private auth = new BehaviorSubject(null);
  currentAuth = this.auth.asObservable();

  constructor(private http: HttpClient) {
  }

  setAuth(data) {
    this.auth.next(data);
  }

  login(data: any) {
    return this.http.post(`${this.base}login`, data)
      .pipe(
        map((success: any) => {
          const tokenEds = `Bearer ${success.success.token}`;
          localStorage.setItem('token-eds', btoa(tokenEds));
          return success.success.token;
        })
      );
  }

  signup(data: any) {
    return this.http.post(`${this.base}signup`, data);
  }

  logout() {
    localStorage.removeItem('token-eds');
    return new Observable((observer) => {
      observer.next(true);
      observer.complete();
    });
  }

  getUser() {
    if (this.logged()) {
      return this.http.post(`${this.base}get-user`, null);
    } else {
      const response = {
        error: true
      };
      return new Observable((subscriber) => {
        subscriber.next(response);
        subscriber.complete();
      });
    }
  }

  getPersonal() {
    if (this.logged()) {
      return this.http.post(`${this.base}get-personal`, null);
    } else {
      const response = {
        error: true
      };
      return new Observable((subscriber) => {
        subscriber.next(response);
        subscriber.complete();
      });
    }
  }

  logged() {
    return localStorage.getItem('token-eds') !== null;
  }

  sendMail(data) {
    return this.http.post(`${this.base}send-mail`, data);
  }
}
