import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as XLSX from 'xlsx';
import {BeneficiarioService} from '../beneficiario.service';
import {Router} from '@angular/router';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-beneficiario-upload',
  templateUrl: './beneficiario-upload.component.html',
  styleUrls: ['./beneficiario-upload.component.scss']
})
export class BeneficiarioUploadComponent implements OnInit {

  @ViewChild('excel', {static: false}) excel: any;

  faDownload = faDownload;

  data: any = null;
  beneficiarios: any = null;
  nuevosBeneficiarios: any = null;
  loading = false;
  percentage = 0;
  counter = 0;
  timer = 0;
  refreshInterval: any = null;

  constructor(private toastr: ToastrService,
              private router: Router,
              private beneficiarioService: BeneficiarioService) {
  }

  static excelToDate(excelDate: number) {
    const dateInfo = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
    const gestion = dateInfo.getFullYear();
    const mes = (dateInfo.getMonth() + 1) < 10 ? '0' + (dateInfo.getMonth() + 1) : (dateInfo.getMonth() + 1);
    const dia = (dateInfo.getDate() < 10) ? '0' + dateInfo.getDate() : dateInfo.getDate();
    return gestion + '-' + mes + '-' + dia;
  }

  ngOnInit() {
  }


  upload() {
    const file = this.excel.nativeElement.files[0];
    if (file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel') {
        this.toastr.error('El archivo no es una hoja de cálculo', 'Excel no encontrado');
      } else {
        this.loading = true;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /*
          * Read workbook
          * */
          const bstr = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {
            type: 'binary'
          });

          /*
          * Grab first sheet
          * */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /*
          * Save data
          * */
          const data: any = XLSX.utils.sheet_to_json(ws, {
            header: 1
          });
          console.log(data);

          let info = '[';
          this.nuevosBeneficiarios = data.length - 1;
          for (let i = 1; i < data.length; i++) {
            info += '{';
            for (let j = 0; j < data[0].length; j++) {
              const key = data[0][j].toLowerCase().replace(/ /gi, '_');
              if (key === 'birthdate' || key === 'enrolledondate' || key === 'createddate') {
                info += '"' + key + '":"' + BeneficiarioUploadComponent.excelToDate(parseInt(data[i][j], 10)) + '"';
              } else {
                if (data[i][j] === undefined || data[i][j] === 'undefined' || data[i][j] === null || data[i][j] === '') {
                  info += `"${key}":null`;
                } else {
                  const limpio = data[i][j].toString().replace(/(\r\n|\n|\r|"|')/gm, '');
                  info += `"${key}":"${limpio}"`;
                }
              }
              if (j !== (data[0].length - 1)) {
                info += ',';
              }
            }
            info += '}';
            if (i !== data.length - 1) {
              info += ',';
            }
          }
          info += ']';
          this.data = JSON.parse(info);
          /*const seconds = 0.065 * this.data.length;*/
          const seconds = 0.13 * this.data.length;
          this.counter = 0;
          this.timer = 0;
          this.refreshInterval = setInterval(() => {
            this.counter = Math.round(this.timer * 100 / seconds);
            this.timer++;
            if (this.counter >= 100) {
              clearInterval(this.refreshInterval);
            }
          }, 1000);
          this.beneficiarioService.importarBeneficiarios(this.data)
            .subscribe((res: any) => {
              if (res.type === HttpEventType.UploadProgress) {
                this.percentage = Math.round(100 * res.loaded / res.total);
              } else if (res instanceof HttpResponse) {
                this.loading = false;
                this.router.navigate(['/admin/beneficiarios/listar']);
                this.toastr.success(`Beneficiarios: ${res.body.nuevos} nuevos,
                          ${res.body.restauraciones} restauraciones y ${res.body.eliminaciones} eliminaciones`,
                  'Importación exitosa');
              }
            }, () => {
              this.toastr.error('Algunos campos no fueron llenados correctamente', 'Excel mal formado');
              this.loading = false;
            });
        };
        reader.readAsBinaryString(file);
      }
    }
  }
}
